import { HttpClient } from "../HttpClient";
/**
 * * Get Creators List
 */
export const GetCreatorsList = async () => {
	try {
		const { data } = await HttpClient.get(`api/creatorvideoupdates/listall`);
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
