import { HttpClient } from "../HttpClient";
/**
 * * Get Video Updates Report
 */
export const GetVideoUpdatesReport = async ({ DateFrom, DateTo, CreatorId, StatusId }) => {
    try {
        const { data } = await HttpClient.get("api/notifications/statusreport", {
            params: {
                DateFrom,
                DateTo,
                ...(CreatorId ? { CreatorId: CreatorId } : {}),
                ...(StatusId ? { StatusId: StatusId } : {}),
            },
            responseType: "blob",
        });
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
