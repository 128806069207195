<template>
    <LayoutNew>
        <h1 class="text-center">Video Update Report</h1>
        <MDBCard>
            <MDBCardHeader class="p-4 pb-3">
                <h3>Download Status Report</h3>
                <h6>Choose date range and user to generate
                    status report. (User is optional)</h6>
            </MDBCardHeader>
            <MDBCardBody tag="form" @submit.prevent="submit">
                <div class="d-md-flex d-block justify-content-center align-items-center mb-1 gap-3">
                    <MDBDatepicker class="mb-md-0 mb-4" v-model="dateFrom" inline label="Date From" />
                    <MDBDatepicker class="mb-md-0 mb-4 mx-1" v-model="dateTo" inline label="Date To" />
                    <MDBSelect v-model:options="options" label="Select User" filter placeholder="Select User"
                        v-model:selected="selectedOption" />
                </div>
            </MDBCardBody>
            <MDBCardFooter class="py-3 d-flex justify-content-end">
                <MDBBtn type="submit" color="primary" size="sm" @click="submit">Download Report</MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardFooter, MDBSelect, MDBDatepicker, MDBBtn } from "mdb-vue-ui-kit";
import { GetVideoUpdatesReport } from '@/services/Notifications/VideoUpdatesReport'
import { ref, onMounted } from "vue";
import { GetCreatorsList } from '@/services/CreatorVideoUpdates/GetCreatorsList'
import { parseDateDash } from "@/helpers/parseDate";
import { useTitle } from "@vueuse/core";

useTitle("Video Update Report | CreatorShield");

const options = ref([{ text: "Loading", value: '' }]);
const dateFrom = ref('');
const dateTo = ref('');
const selectedOption = ref('')

const submit = async () => {
    let selectedId = 0;
    let selectedName = '';
    if (selectedOption.value) {
        const selectedArr = selectedOption.value.split("-")
        selectedId = selectedArr[0];
        selectedName = selectedArr[1];
    }
    if (dateFrom.value > dateTo.value) {
        alert('Date From must not be greater than Date To');
        return;
    }
    if (dateFrom.value === '' || dateTo.value === '') {
        alert('Dates Must not be empty');
        return;
    }
    const params = { DateFrom: parseDateDash(dateFrom.value), DateTo: parseDateDash(dateTo.value), ...(selectedId !== 0 ? { CreatorId: selectedId } : {}) }
    const response = await GetVideoUpdatesReport(params);
    let fileURL = window.URL.createObjectURL(
        new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
        "download",
        `CreatorShield-VideoUpdatesStatusReport ${selectedId !== 0 ? '(' + selectedName + ')' : ''} - ${parseDateDash(dateFrom.value)} - ${parseDateDash(dateTo.value)}.xlsx`
    );
    document.body.appendChild(fileLink);
    fileLink.click();
};

onMounted(async () => {
    const response = await GetCreatorsList();
    options.value = response.map((creator) => {
        return {
            ...creator,
            text: `${creator.creatorName}`,
            value: `${creator.id}-${creator.creatorName}`,
        }
    });
    options.value.unshift({
        text: 'All',
        value: 0
    });
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>